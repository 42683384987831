<template>
    <div class="infoMain">
        <alert-info :info="{ zysx: '加“*”是必填项，请按规定填写！' }"></alert-info>
        <div class="userinfo roleinfomain scrollbar">
            <el-form ref="roleBasic" :model="roleBasic" :rules="rules" label-width="100px" class="demo-ruleForm">
                <el-form-item label="角色名称" prop="Name">
                    <el-input v-model="roleBasic.Name" onkeydown="if(event.keyCode==32) return false"></el-input>
                </el-form-item>
                <el-form-item label="部门" prop="depids" class="tree-select-form-item">
                    <SnTreeselect
                        :treeselectValue="orgData.selectValue"
                        :treeselectData="orgData"
                        @handleChange="orgChange"
                    ></SnTreeselect>
                </el-form-item>
                <el-form-item label="角色权限" prop="">
                    <el-tree ref="tree" class="role-func-tree scrollbar" :data="dataTree" show-checkbox node-key="ID" :default-expanded-keys="selectArr" :default-checked-keys="selectArr" :props="defaultProps" @check-change="getCheckedKeys">
                    </el-tree>
                </el-form-item>
                <el-form-item label="数据权限" prop="">
                    <div class="treeBox">
                        <ul>
                            <li v-for="(item, index) in treeData" :key="index" class="treeItem">
                                <p class="name">{{item.name}}</p>
                                <div class="check_part">
                                    <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll" @change="((val) => handleCheckAllChange(item, val))">全选</el-checkbox>
                                    <el-checkbox-group v-model="item.checkSomeList" @change="((val) => handleCheckSomeChange(item, val))">
                                        <el-checkbox v-for="check in checkAllList" :label="check.code" :key="check.code" :indeterminate="item.checkSomeType[check.code]">{{check.label}}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <el-tree
                                    ref="funcTree"
                                    class="role-func-tree dep-func-tree scrollbar"
                                    :data="item.data"
                                    show-checkbox
                                    node-key="ID"
                                    :default-expanded-keys="item.detailList"
                                    :default-checked-keys="item.detailList"
                                    highlight-current
                                    :props="defaultFuncProps"
                                    @check="((val) => setCheckBtnType(index, val))"
                                >
                                </el-tree>
                            </li>
                        </ul>
                    </div>
                </el-form-item>
                <el-form-item label="角色职责" prop="Description">
                    <el-input v-model="roleBasic.Description" type="textarea" :autosize="{ minRows: 3 }" :maxlength="200" onkeydown="if(event.keyCode==32) return false"></el-input>
                </el-form-item>
                <el-form-item class="buttonItem">
                    <sn-button v-if="canEdit" size="small" :snButton="saveBtn" @handleChange="save">保存</sn-button>
                    <router-link :to="{ name: 'role' }">
                        <sn-button :snButton="cancleBtn" size="small"></sn-button>
                    </router-link>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>

import alertInfo from '@comp/alert_info';
import { mapActions } from 'vuex';
export default {
    name: 'roleinfo',
    components: {
        alertInfo,
    },
    data() {
        // 输入角色名 Name
        let validateNAME = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入角色名'));
            } else {
                if (value !== this.nameold) {
                    this.checkName(value).then(val => {
                        if (!val) {
                            callback(new Error('角色名称已存在'));
                        } else {
                            callback();
                        }
                    });
                } else {
                    callback();
                }
            }
        };
        return {
            // 数据权限
            checkAllList: [
                { label: '可导出', code: '3' },
                { label: '可编辑', code: '1' }, 
                { label: '可审核', code: '2' }, 
                { label: '可查看', code: '0' },
            ],
            treeData: [
                {
                    index: 0,
                    name: '基础数据',
                    code: 'JiChu',
                    data: [],
                    checkList: [],
                    detailList: [], // 默认选中的节点
                    allChildren: [], // 全部子节点
                    isIndeterminate: false, // 是否半选
                    checkAll: false, // 是否全选
                    checkSomeList: [], // 当前批量选中的类型
                    checkSomeListOld: [], // 上次批量选中的类型
                    checkSomeType: { // 子级选择框是否半选
                        '0': false,
                        '1': false,
                        '2': false,
                        '3': false
                    }
                },
                {
                    index: 1,
                    name: '监测数据',
                    code: 'JianCe',
                    data: [],
                    checkList: [],
                    detailList: [],
                    allChildren: [],
                    isIndeterminate: false,
                    checkAll: false,
                    checkSomeList: [],
                    checkSomeListOld: [],
                    checkSomeType: {
                        '0': false,
                        '1': false,
                        '2': false,
                        '3': false
                    }
                }
            ],
            defaultFuncProps: {
                label: 'NAME',
                children: 'child'
            },
            rules: {
                Name: [
                    { required: true, validator: validateNAME, trigger: 'blur' },
                ],
            },
            saveBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'button',
                    operateType: 'save',
                    name: '保存',
                    round: true,
                    backColor: '#28ccd9',
                }]
            },
            cancleBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'button',
                    operateType: '',
                    name: '取消',
                    round: true,
                    backColor: '#ff9066',
                }]
            },
            newdoc: 'xinjian',
            strId: null,
            canEdit: false,
            roleBasic: {
                "Name": '', // 角色名称
                "Description": '', // 角色职责
                "depids": [], // 机构
            },
            nameold: null,
            // 功能权限
            dataTree: [],
            defaultProps: {
                children: 'CHILDREN',
                label: 'NAME'
            },
            selectArr: [], // 获取详情功能权限
            allfunArr: [], // 改变时的功能权限
            selectArrData: [],
            orgData: {
                isShow: true,
                selectValue: [],
                clearable: true,
                multiple: true,
                searchable: true,
                disableBranchNodes: false,
                placeholder: "请选择机构",
                list: [],
            }
        };
    },
    mounted() {
        let _this = this;
        _this.newdoc = _this.$route.params.newdoc;
        _this.strId = _this.$route.params.strId;
        _this.canEdit = _this.$route.params.canEdit;
        _this.getorg();
        if (_this.newdoc === 'bianji') {
            _this.getRoleInfo(_this.strId);
        } else {
            _this.getFunc();
            _this.getTreeItem();
        }
    },
    methods: {
        ...mapActions([
            'getRoleData',
            'checkRoleName',
            'saveRoleData',
            'updateRoleData',
            'getDepNameList',
            'getFuncTree',
            'getDataItem',
        ]),
        async getTreeItem() {
            for (let i = 0; i < this.treeData.length; i++) {
                let res = await this.getDataItem({
                    dataType: this.treeData[i].index
                });
                if (res.IsSuccess) {
                    let da = res.ResultValue.Data;
                    this.treeData[i].data = da;
                    this.treeData[i].allChildren = [];
                    this.getResult(da, i);
                    if (this.newdoc === 'bianji') {
                        this.$nextTick(() => {
                            this.setCheckBtnType(i);
                        });
                    }
                }
            }
        },
        setCheckBtnType(i) {
            let data = this.treeData[i];
            let arr = this.$refs.funcTree[i].getCheckedKeys(true);
            data.checkSomeList = [];
            Object.keys(data.checkSomeType).forEach(type => {
                let checkedLength = arr.filter(code => code && code.slice(code.length - 1) == type).length;
                let typeLength = data.allChildren.filter(code => code && code.slice(code.length - 1) == type).length;
                let checkAll = checkedLength === typeLength ? true : false;
                data.checkSomeType[type] = (checkedLength === 0 || checkAll) ? false : true;
                if (checkAll) {
                    data.checkSomeList.push(type);
                }
            });
            data.checkAll = data.checkSomeList.length == this.checkAllList.length;
            data.isIndeterminate = (arr == 0 || data.checkAll) ? false : true;
            data.checkSomeListOld = data.checkSomeList;
        },
        getResult(da, index) {
            let _this = this;
            da.forEach(item => {
                if (item.child && item.child.length > 0) {
                    this.getResult(item.child, index);
                } else if (item.CZQX && item.CZQX.length > 0) {
                    item.CZQX.forEach(i => {
                        i.ID = item.ID + i.CZQX;
                        _this.treeData[index].allChildren.push(i.ID);
                    });
                    item.child = item.CZQX || [];
                }
            });
        },
        async checkName(name) {
            let res = await this.checkRoleName({
                name: name
            });
            return res;
        },
        // 获得角色详细信息
        async getRoleInfo() {
            let res = await this.getRoleData({
                id: this.strId
            });
            Object.keys(res.Role[0]).forEach(key => {
                this.roleBasic[key] = res.Role[0][key];
            });
            this.roleBasic.depids = res.DEP.map(item => item.Depid);
            this.orgData.selectValue = res.DEP.map(item => item.Depid);
            this.nameold = res.Role[0].Name;
            // 数据权限
            this.treeData.forEach(item => {
                let da = res[item.code];
                item.checkList = [];
                da.forEach(i => {
                    if (i.Czqx !== '' && i.Czqx !== null) {
                        let arr = i.Czqx.split(',');
                        arr.forEach(ele => {
                            item.detailList.push(i.Dataitemid + ele);
                        });
                        item.checkList.push({
                            DATAITEMID: i.Dataitemid,
                            CZQX: i.Czqx
                        });
                        // item.detailList.push(...i.Czqx.split(','));
                    }
                });
            });
            this.getTreeItem();
            // 角色权限
            this.selectArrData = res.Fun;
            this.allfunArr = [];
            this.selectArrData.forEach(item => {
                this.allfunArr.push(item.Privsid);
            });
            this.getFunc();
        },
        save() {
            let _this = this;
            if (_this.allfunArr.length == 0) {
                _this.$message({ message: '请选择角色权限', type: 'warning' });
                return;
            }
            this.$refs.roleBasic.validate((valid) => {
                if (valid) {
                    let msg = '此操作将添加到数据库, 是否继续?';
                    _this.$confirm(msg, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.saveBtn.buttonData[0].disabled = true;
                                let fwname = '';
                                let fwparams = {};
                                if (_this.newdoc === 'xinjian') {
                                    // 新建角色服务提交
                                    fwname = 'saveRoleData';
                                }
                                if (_this.newdoc === 'bianji') {
                                    // 新建角色服务提交
                                    fwname = 'updateRoleData';
                                }
                                let depArr = _this.roleBasic.depids || [];
                                let depids = depArr.join(',');
                                delete _this.roleBasic.depids;
                                let info = JSON.stringify(_this.roleBasic);
                                // 数据权限
                                this.treeData.forEach(item => {
                                    this.getFuncCheckedKeys('', item.index);
                                });
                                let funcArr = [];
                                this.treeData.forEach(item => {
                                    funcArr.push(...item.checkList);
                                });
                                let dataids = JSON.stringify(funcArr);
                                fwparams = {
                                    info: info,
                                    funArrid: this.allfunArr.join(),
                                    dataids: dataids,
                                    depids: depids
                                };
                                _this.saveRole(fwname, fwparams, done);
                            } else {
                                done();
                            }
                        }
                    })
                        .then(() => {
                        })
                        .catch(() => {
                            _this.$message({ message: '已取消保存', type: 'info' });
                        });

                } else {
                    return false;
                }
            });
        },
        async saveRole(fwname, fwparams, done) {
            let res = await this[fwname](fwparams);
            if (res.IsSuccess) {
                done();
                this.$message({ message: '保存成功', type: 'success' });
                this.$router.push({
                    name: 'role'
                });
            } else {
                this.$message({ message: '保存失败', type: 'error' });
            }
        },
        // 获得机构列表
        async getorg() {
            let res = await this.getDepNameList();
            this.orgData.list = res;
        },
        orgChange(type, value) {
            this.roleBasic.depids = value[0];
        },
        async getFunc() {
            let res = await this.getFuncTree();
            this.dataTree = res;
            this.selectArr = [];
            let alldata = [];
            this.dataTree.forEach(item => {
                if (item.CHILDREN && item.CHILDREN.length != 0) {
                    item.CHILDREN.forEach(v => {
                        alldata.push(v.ID);
                    });
                } else {
                    alldata.push(item.ID);
                }
            });
            this.selectArrData.forEach(v => {
                if (alldata.includes(v.Privsid)) {
                    this.selectArr.push(v.Privsid);
                }
            });
            this.$refs.tree.setCheckedKeys(this.selectArr);
        },
        getCheckedKeys(data, add, no) { // 功能权限
            let arr = this.$refs.tree.getCheckedNodes(false, true);
            this.allfunArr = [];
            arr.forEach(item => {
                this.allfunArr.push(item.ID);
            });
        },
        getFuncCheckedKeys(val, index) { // 数据权限
            let arr = this.$refs.funcTree[index].getCheckedNodes(false, true);
            this.treeData[index].checkList = [];
            let checkList = this.treeData[index].checkList;
            arr.forEach((item, i) => {
                // new 后台需要传每一层级
                if (item.ID.length === 36 && arr[i + 1] && arr[i + 1].ID.length === 36) { // 父级
                    checkList.push({
                        DATAITEMID: item.ID,
                    });
                } else if (item.ID.length === 37) { // 子级
                    let isID = arr[i - 1].ID.length === 37; // 第二个子级
                    if (!isID) {
                        checkList.push({
                            DATAITEMID: arr[i - 1].ID,
                            Czqx: item.ID.substr(36)
                        });
                    } else {
                        checkList[checkList.length - 1].Czqx += ',' + item.ID.substr(36);
                    }
                }
            });
        },
        handleCheckAllChange(item, val) {
            let keys = val ? item.allChildren : [];
            this.$refs.funcTree[item.index].setCheckedKeys(keys);
            this.setCheckBtnType(item.index);
        },
        handleCheckSomeChange(item, val) {
            let checkedCount = val.length;
            item.checkAll = checkedCount === this.checkAllList.length;
            if (item.checkAll) {
                this.$refs.funcTree[item.index].setCheckedKeys(item.allChildren);
            } else {
                let list = this.$refs.funcTree[item.index].getCheckedKeys(true);
                this.checkAllList.forEach(itemCheck => {
                    if (val.indexOf(itemCheck.code) > -1 && item.checkSomeListOld.indexOf(itemCheck.code) === -1) { // 选中
                        list.push(...(item.allChildren.filter(i => i.slice(i.length - 1) == itemCheck.code)));
                    } else { // 取消选中
                        let deleteList = item.checkSomeListOld.filter(type => val.indexOf(type) === -1);
                        list = list.filter((listID, i) => {
                            return deleteList.indexOf(listID.slice(listID.length - 1)) == -1;
                        });
                    }
                })
                let res = Array.from(new Set(list));
                this.$refs.funcTree[item.index].setCheckedKeys(res);
            }
            this.setCheckBtnType(item.index);
        }
    },
};
</script>
<style lang="scss">
.roleinfomain {
    .tree-select-form-item .vue-treeselect {
        line-height: 30px;
        margin-top: 2px;
        .vue-treeselect__multi-value-item-container {
            line-height: 16px;
        }
        .vue-treeselect__multi-value {
            margin-bottom: 0;
        }
    }
}
</style>
<style lang="scss" scoped>
.el-tree {
    height: 240px!important;
    overflow-y: scroll;
}
.treeBox {
    width: 100%;
    height: 340px;
    ul {
        display: block;
        width: 100%;
        height: 100%;
        .treeItem {
            width: calc(50% - 5px);
            float: left;
            height: 400px;
            .name {
                height: 26px;
                font-size: 12px;
                color: #98a6b3;
            }
            .check_part {
                background: #fff;
                margin-bottom: 5px;
                padding-left: 24px;
                >.el-checkbox,
                >.el-checkbox-group {
                    height: 30px;
                    line-height: 30px;
                }
                .el-checkbox {
                    margin-right: 13px;
                }
            }
            &:nth-child(1) {
                margin-right: 10px
            }
            .el-tree {
                height: 300px!important;
                .el-tree-node__content {
                    line-height: 20px;
                    height: auto!important;
                    .custom-tree-node {
                        span:nth-child(1) {
                            // width: 110px;
                            display: inline-block;
                            word-wrap: break-word;
                            word-break: normal;
                            white-space: normal;
                        }
                    }
                }
                .el-checkbox.checkData {
                    margin-right: 10px;
                }
            }
        }
    }
}
</style>